<template>
  <b-row>
    <b-col cols="3" />
    <b-col cols="6">
      <b-card
        class="card-congratulation-medal pr-3"

        style="display: block"
      >
        <b-card-body class="mt-1 mb-2 pr-3">
          <h5><strong> X123456 criada com sucesso 🎉</strong></h5>
          <b-card-text class="font-small-3">
            Agora basta ativá-la para que seus usuários possam começar a indicar!
          </b-card-text>
        </b-card-body>
        <b-img
          :src="require('@/assets/images/illustration/badge.svg')"
          class="congratulation-medal"
          alt="Medal Pic"
        />

        <div
          style="text-align: right"
          class="pt-1"
        >
          <router-link
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            to="/campaign/list"
            variant="primary"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="15"
              class="text-primary"
            />
            Ir para a lista de campanhas
          </router-link>
        </div>
      </b-card>

      <b-card-code
        class="card-congratulation-medal pr-3"
      >
        <b-card-body class="mt-1 mb-2 pr-3">
          <h5><strong> X123456 criada com sucesso 🎉</strong></h5>
          <b-card-text class="font-small-3">
            Antes de ativa-la, certifique que a página de destino da campanha
            esteja configurada corretamente para que seus usuários possam
            começar a indicar!
          </b-card-text>

          <h4 class="mt-2">
            Instruções para integração
          </h4>
          <p>Siga as intruções abaixo para fazer a integração com sua página de conversão.</p>

          <p>1. Copie e cole o código abaixo e adicione entre as tags <br> <code>&#60;head&#62;&#60;&#47;head&#62; </code> do seu site.</p>

          <p>
            <code>
              &#60;script src="https://indiquei.vc/widget/init.js" &#47;&#62;
            </code>
          </p>

          <p class="mt-2">
            2. Você de adicionar o elemento HTML <br> <code>&#60;indiquei-vc-widget&#62;&#60;&#47;indiquei-vc-widget&#62;</code> dentro do código html da página onde deseja que o formulário de conversão apareça.
          </p>

          <p class="mt-2">
            <a
              href="#"
              target="_blank"
            >Ou clique aqui para ter acesso as APIs</a>
          </p>
        </b-card-body>
        <b-img
          :src="require('@/assets/images/illustration/badge.svg')"
          class="congratulation-medal"
          alt="Medal Pic"
        />

        <div
          style="text-align: right"
          class="pt-1"
        >
          <router-link
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            to="/campaign/list"
            variant="primary"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="15"
              class="text-primary"
            />
            Ir para a lista de campanhas
          </router-link>
        </div>
      </b-card-code>
    </b-col>
    <b-col cols="3" />
  </b-row>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardBody, BImg,
} from 'bootstrap-vue'

import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BCardCode,
  },
  data() {
    return {
      script: '<script src="https://indiquei.vc/widget/init.js"/>',
    }
  },
}
</script>
